:root {
  --header-color: #96BDC6;
  --background-color: #81968F;
  --entry-color: #E9D6EC;
  --button-color: #C8553D; 
  --display-color: #E8CCBF;
}

html, body {
  height: 100%;
  width: 100%;

  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
  width: 100%;
}

.wholePageContainer {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

}

.header {
  flex-grow: 1;
  background-color: var(--header-color);
  color: black;
  border: solid #13315C;
  font-size: 1.25em;
  font-weight: bold;

  cursor: pointer;
}

.title {
  text-align: center;
  font-family: monospace;
  font-weight: bold;
  font-size: 3em;

  padding: 0px;
  margin: 0px;
}

.contentContainer {
  flex-grow: 14;

  display: flex;
  flex-direction: column;
  
}

.contentRateContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.contentHome {
  flex: 1;

  display: flex;
  flex-direction: row;

  background-color: var(--background-color);
}

.homeMargin {
  flex-grow: 1;
  background-color: var(--background-color);
}

.homeButtonArray {
  flex-grow: 5;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.homeButton {
  font-size: 2em;
  font-weight: bold;
  height: 10%;
  border: solid #134074;
  background-color: var(--button-color);
  color: black;

  cursor: pointer;
}

.contentRephrase {
  flex: 1;

  display: flex;
  flex-direction: row;

  background-color: var(--background-color);
}

.rephraseContainer {
  flex-grow: 3;
  flex-basis: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.displayTextPanel {
  flex-grow: 2;

  display: flex;
  flex-direction: column;
}

.textCard {
  height: 50%;
  width: 100%;
}

.textEntryPanel {
  flex-grow: 2;

  display: flex;
  flex-direction: column;
}

.rephraseButtonPanel {
  flex-grow: 1;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.rephraseButton {
  width: 45%;
  height: 80%;

  font-size: 2em;
  font-weight: bold;

  border: solid black;
  background-color: var(--button-color);
  color: black;

  cursor: pointer;
}


.displayTextArea {
  box-sizing: border-box;
  flex: 1;

  background-color: var(--display-color);
  font-weight: 600;
  font-size: 19px;
  resize: none;
  cursor: default;
  margin: 0px;
  padding: 0px;

  border: solid black;
}

.enterTextArea {
  box-sizing: border-box;
  flex: 1;
  margin: 0px;
  padding: 0px;
  background-color: var(--entry-color);
  color: black;
  font-weight: 600;
  font-size: 19px;
  resize: none;

  border: solid black;
}

.rephraseTitle {
  text-align: center;
  margin: 0px;
  width: 100%;
  color: black;
}

.contentRate {
  flex: 14;
  display: flex;
  flex-direction: column;

  background-color: var(--background-color);
}

.displayOnly {
  background-color: var(--display-color);
  color: black;
}

.rateRowOne {
  flex-grow: 4;

  display: flex;
  flex-direction: row;
}

.rateRowTwo {
  flex-grow: 4;
  display: flex;
  flex-direction: row;
}

.rateRowThree {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.rateTextContainer {
  flex-grow: 8;

  display: flex;
  flex-direction: row;
}

.voteButtonContainer {
  flex-grow: 1;
}

.voteButton {
  font-size: 2em;
  font-weight: bold;

  border: solid black;
  background-color: var(--button-color);
  color: black;
  
  width: 100%;
  height: 100%;

  cursor: pointer;
}

.contentRequest {
  display: flex;
  flex-direction: column;

  flex: 14;

  background-color: var(--background-color);
}

.goalSelectionContainer {
  display: flex;
  flex-direction: row;

  justify-content: center;
}

.requestGoalSelector {
  font-size: 2em;
  font-weight: bold;
  border-radius: 12px;
  background-color: var(--entry-color);
}

.requestTitle {
  margin-right: 1%;
  text-align: right;
}

.requestEntryContainer {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
}

.requestButtonPanel {
  flex-grow: 1;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.contentReview {
  display: flex;
  flex-direction: column;

  flex: 1;

  background-color: var(--background-color);
}

.reviewCurrentPanel {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.reviewHeader {
  display: flex;
  flex-direction: row;

  justify-content: flex;

}

.reviewTextContainer {
  display: flex;
  flex-direction: row;

  height: 40%;
}

.phraseDisplayPreface {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.phraseDisplayScore {
  width: 100%;
  font-weight: bold;
  text-align: center;
  background-color: green;
  box-sizing: border-box;
}

.reviewOriginalPhrase {
  flex: 5;
  background-color: lightpink;
  border: solid red;
  box-sizing: border-box;
}

.reviewTopPhrase {
  flex: 5;
  background-color: lightgreen;
  border: solid green;
}

.reviewGoalTitle {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 1.5em;
}

.reviewPanel {
  flex: 6;
  display: flex;
  flex-direction: column;
}

.phraseListContainer {
  overflow-y: auto;
  flex: 1 0 10px;
}

.reviewButtonsPanel {
  flex: 1;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.bottomButton {
  height: 100%;
  width: 50%;

  font-size: 2em;
  font-weight: bold;

  border: solid black;
  background-color: var(--button-color);
  color: black;

  cursor: pointer;
}

.reviewButton {
  height: 100%;
  width: 50%;
  font-size: 2em;
  font-weight: bold;

  border: solid black;
  background-color: var(--button-color);
  color: black;

  cursor: pointer;
}

.contentReflect {
  flex: 1;

  display: flex;
  flex-direction: column;

  background-color: var(--background-color);
}

.reflectIdentityPanel {
  flex: 2;

  display: flex;
  flex-direction: row;
}

.reflectMetricsPanel {
  flex: 7;

  display: flex;
  flex-direction: column;

  align-items: center;
}

.reflectButtonsPanel {
  flex: 1;
}

.reflectIdentityText {
  flex: 1;
  background-color: var(--display-color) ;
  font-weight: 600;
  font-size: 19px;
  resize: none;
  cursor: default;
  margin: 0px;
  padding: 0px;

  border: solid black;
  color: black;

  text-align: center;
  
}

.reflectIdentityHeaders {
  display: flex;
  flex-direction: row;
}

.reflectHeader {
  flex: 1;

  text-align: center;
  font-weight: bold;
  font-size: 1.5em;
}

.reflectScore {
  font-weight: 800;
  font-size: 1.5em;
}


@media screen and (orientation:portrait) {
  .reviewTextContainer {
    flex-direction: column;
    height: 100%;
  }
}
